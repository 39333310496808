import React, {
  useCallback,
  useContext,
  useMemo,
  useSyncExternalStore
} from 'react';
import _ from 'lodash';

import { AllAlarmSeverities } from 'ecto-common/lib/constants';
import { isRootNode } from 'ecto-common/lib/utils/locationUtils';
import { useAlarmCountEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from 'ecto-common/lib/Dashboard/panels/AlarmStatusPanel.module.css';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { NavLink } from 'react-router-dom';

import {
  getSignalProvidersPage,
  getAlarmsPage
} from 'ecto-common/lib/utils/commonLinkUtil';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { ASC } from 'ecto-common/lib/DataTable/SortDirection';
import useSort from 'ecto-common/lib/hooks/useSort';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import AlarmSeverityCount from 'ecto-common/lib/Alarms/AlarmSeverityCount';
import { beautifyEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import HelpPaths from 'ecto-common/help/tocKeys';

import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import { buildingStatusSection } from './panelUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  BuildingStatus,
  EquipmentResponseModel,
  EquipmentTypeResponseModel,
  NodeType
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';

const pathName = window.location.pathname;

const getAlarmsRoute = (tenantId: string, node: AlarmNode, isAdmin: boolean) =>
  !isAdmin ? getAlarmsPage(tenantId, node.nodeId) : pathName;

const isSite = (type: string) => type === NodeTypes.SITE;

const isBuilding = (type: string) => type === NodeTypes.BUILDING;

interface NameColumnTitleProps {
  node: AlarmNode;
}

const NameColumnTitle = ({ node }: NameColumnTitleProps) => {
  const { isAdmin, setNode } = useContext(DashboardDataContext);
  const { tenantId } = useContext(TenantContext);

  const _setNode = useCallback(() => {
    if (
      (isAdmin && isSite(node.nodeType)) ||
      isBuilding(node.nodeType) ||
      !isAdmin
    ) {
      setNode(node.nodeId);
    }
  }, [isAdmin, node.nodeId, node.nodeType, setNode]);

  // TODO: Remove this cast
  if (!node.equipmentId) {
    return (
      <a onClick={_setNode} className={styles.nameWrapper}>
        {node.name}
      </a>
    );
  }

  if (!isAdmin) {
    // TODO: Remove cast
    return (
      <NavLink
        to={getSignalProvidersPage(tenantId, node.nodeId, node.equipmentId)}
        className={styles.nameWrapper}
      >
        {node.name}
      </NavLink>
    );
  }

  return <>{node.name}</>;
};

interface NameColumnProps {
  node: AlarmNode;
}

const NameColumn = ({ node }: NameColumnProps) => (
  <TableColumn
    className={styles.nameWrapper}
    title={<NameColumnTitle node={node} />}
    subtitle={node.subtitle}
  />
);

const approxWidthForSeverityColumn = (maxNumSeverities: number) => {
  // Approx width for severity with two digit value
  const SEVERITY_WIDTH_APPROX = 55;
  // Approx width for navigation arrow
  const SEVERITY_ARROW_APPROX = 25;

  return maxNumSeverities * SEVERITY_WIDTH_APPROX + SEVERITY_ARROW_APPROX;
};

interface SeveritiesColumnProps {
  node: AlarmNode;
}

const SeveritiesColumn = ({ node }: SeveritiesColumnProps) => {
  const { isAdmin } = useContext(DashboardDataContext);
  const { tenantId } = useContext(TenantContext);

  return (
    <NavLink
      to={getAlarmsRoute(tenantId, node, isAdmin)}
      className={styles.alarmSeverities}
    >
      {_.map(node.severities, (count, severity) => (
        <AlarmSeverityCount key={severity} severity={severity} count={count} />
      ))}

      {_.isEmpty(node?.severities) && (
        <Tooltip text={T.admin.alarmstatuspanel.tooltip.noactivealarms}>
          <Icons.CheckmarkCircle className={styles.noSeveritiesIcon} />
        </Tooltip>
      )}

      <div className={styles.arrow}>
        <Icons.NavigationArrowRight />
      </div>
    </NavLink>
  );
};

type AlarmDataTableColumnProps = DataTableColumnProps<AlarmNode> & {
  sortBy?: string[];
};

const getColumns = (maxNumSeverities: number): AlarmDataTableColumnProps[] => {
  // Table columns must have constant width in order to avoid
  // overlap. Make an approximate width for the severity column (which is dynamic),
  const severitiesMinWidth = approxWidthForSeverityColumn(maxNumSeverities);

  return [
    {
      dataKey: 'name',
      label: T.common.nodename,
      canSort: true,
      truncateText: true,
      flexGrow: 2,
      flexShrink: 2,
      dataFormatter: (unused: unknown, node) => <NameColumn node={node} />
    },
    {
      dataKey: 'severities',
      label: T.common.status,
      align: 'right',
      canSort: true,
      flexGrow: 0,
      flexShrink: 0,
      minWidth: severitiesMinWidth,
      maxWidth: severitiesMinWidth,
      sortBy: _.map(AllAlarmSeverities, (severity) => 'severities.' + severity),
      dataFormatter: (unused: unknown, node) => <SeveritiesColumn node={node} />
    }
  ];
};

const getSubtitle = (
  node: SingleGridNode | EquipmentResponseModel,
  equipmentTypes: EquipmentTypeResponseModel[]
) => {
  const merged: Partial<SingleGridNode & EquipmentResponseModel> = node;

  if (isSite(merged?.nodeType)) {
    return T.nodetypes.site;
  }

  if (isBuilding(merged?.nodeType)) {
    return T.nodetypes.building;
  }

  const equipmentTypeId = merged?.equipmentTypeId;

  if (equipmentTypeId) {
    return beautifyEquipmentName(
      _.find(equipmentTypes, { equipmentTypeId })?.name
    );
  }

  return '';
};

type AlarmNode = {
  name: string;
  nodeId: string;
  severities: number[];
  subtitle: string;
  parentNodeId: string;
  equipmentId: string;
  nodeType: NodeType;
};

type AlarmStatusPanelProps = CustomPanelProps & {
  data: {
    node: SingleGridNode;
    buildingStatus: BuildingStatus[];
  };
};

const AlarmStatusPanel = ({ data }: AlarmStatusPanelProps) => {
  const parentNode = data.node;
  const grid = parentNode?.grid;

  const isStandingOnASite = data.node && isSite(data.node?.nodeType);
  const { equipmentMap, equipmentTypes, nodeMap } =
    useContext(DashboardDataContext);

  const nodeIds = useMemo(() => {
    if (parentNode == null) {
      return [];
    }

    if (isStandingOnASite || isRootNode(parentNode)) {
      return _.map(parentNode.children, 'nodeId');
    }

    return [parentNode.nodeId];
  }, [isStandingOnASite, parentNode]);

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const alarmCounts = useAlarmCountEventHubSubscription(
    parentNode?.grid,
    nodeIds,
    // Previous alarms version 1 was too slow on recursive calls, hence the default false.
    // Use recursive in new alarms api, so we get correct number of alarms for parent node
    featureFlagState.oldalarms ? false : true,
    data?.buildingStatus
  );

  const nodesWithAlarmSeverities: AlarmNode[] = useMemo(
    () =>
      _.compact(
        _.map(alarmCounts.bySeverity, (item, key) => {
          let node: SingleGridNode | EquipmentResponseModel = getNodeFromMap(
            equipmentMap,
            key
          );

          if (!node) {
            // When we request counts from a building we do a recursive search based on the building
            // ID. The results will also return entries for each equipment, but also for the building
            // itself. We don't want to show that entry, so don't attempt to fetch it here.
            if (!isStandingOnASite) {
              return false;
            }

            node = getNodeFromMap(nodeMap, key);

            if (!node) {
              return false;
            }
          }

          const _parentNode = getNodeFromMap(nodeMap, node.nodeId);

          return {
            name: node?.name,
            nodeId: node?.nodeId,
            grid,
            severities: item,
            subtitle: getSubtitle(node, equipmentTypes),
            parentNodeId: _parentNode?.nodeId,
            equipmentId: (node as EquipmentResponseModel)?.equipmentId,
            nodeType: (node as SingleGridNode)?.nodeType
          };
        })
      ),
    [
      alarmCounts.bySeverity,
      equipmentMap,
      equipmentTypes,
      grid,
      isStandingOnASite,
      nodeMap
    ]
  );

  const maxNumSeverities = Math.max(
    1,
    _.max(
      _.map(nodesWithAlarmSeverities, (node) => _.keys(node.severities).length)
    ) ?? 0
  );

  const columns = useMemo(
    () => getColumns(maxNumSeverities),
    [maxNumSeverities]
  );

  const [sortBy, sortDirection, setSortParams, sortedData] = useSort<AlarmNode>(
    'severities',
    ASC,
    columns,
    nodesWithAlarmSeverities
  );

  const isLoading = alarmCounts.isLoading;

  return (
    <DataTable<AlarmNode>
      data={sortedData}
      columns={columns}
      isLoading={isLoading}
      noDataText={T.alarms.noalarmswerefoundinthecurrentlocation}
      onSortChange={setSortParams}
      sortBy={sortBy}
      sortDirection={sortDirection}
    />
  );
};

export const AlarmStatusPanelData = {
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  },
  sections: [buildingStatusSection],
  minWidth: 280,
  helpPath: HelpPaths.docs.dashboard.dashboards.alarm_status_list
};

export default React.memo(AlarmStatusPanel);
