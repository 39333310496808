import React, {
  useContext,
  useEffect,
  useMemo,
  useSyncExternalStore
} from 'react';
import _ from 'lodash';

import Page, { PageNavLink } from 'ecto-common/lib/Page/Page';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';

import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import NoAdminAccessInfo from 'js/components/NoAdminAccessInfo/NoAdminAccessInfo';
import {
  hasAdminRole,
  navLinksFilteredByTenantResources
} from 'ecto-common/lib/utils/tenantUtil';
import resetRequests from 'js/actions/resetRequests';
import { useAdminDispatch } from 'js/reducers/storeAdmin';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import {
  LocationRoute,
  TemplateManagementRoute,
  TenantUsersAdminRoute,
  GlobalTenantAdminRoute,
  IntegrationAccountsRoute,
  BuildingsRoute,
  ProvisioningRoute,
  NotificationsRoute,
  EctotableRoute,
  IntegrationsRoute,
  MeteorologyRoute,
  ProcessMapsManagementRoute,
  NotificationsV2Route,
  AlarmRulesRoute,
  DashboardsManagementRoute,
  GlobalNotificationsRoute
} from '../utils/routeConstants';
import { generatePath } from 'react-router';

const nodePath = (tenantId: string, nodeId: string, page: string) =>
  `/${tenantId}/home/${nodeId}/${page}`;

const adminNavLinks: PageNavLink[] = [
  {
    title: T.sidebar.sections.manage,
    links: [
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'dashboard'),
        name: T.admin.tabs.locations,
        icon: <Icons.Site />,
        resourceTypes: LocationRoute.resourceTypes,
        hideIfHasResourceTypes: LocationRoute.hideIfHasResourceTypes
      },
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'signalmapping'),
        name: T.admin.tabs.signalmapping,
        icon: <Icons.Signal />,
        resourceTypes: LocationRoute.resourceTypes,
        hideIfHasResourceTypes: LocationRoute.hideIfHasResourceTypes
      },
      {
        name: T.admin.tabs.meteorology,
        icon: <Icons.Weather />,
        path: (tenantId: string) => `/${tenantId}/meteorology`,
        resourceTypes: MeteorologyRoute.resourceTypes,
        hideIfHasResourceTypes: MeteorologyRoute.hideIfHasResourceTypes
      },
      {
        name: T.admin.tabs.notifications + ' (old)',
        icon: <Icons.Notification />,
        path: (tenantId: string) =>
          generatePath(NotificationsRoute.path, { tenantId }),
        resourceTypes: NotificationsRoute.resourceTypes,
        hideIfHasResourceTypes: NotificationsRoute.hideIfHasResourceTypes,
        featureFlag: 'oldalarms'
      },
      {
        name: T.admin.tabs.notifications,
        icon: <Icons.Notification />,
        path: (tenantId: string) =>
          generatePath(NotificationsV2Route.path, { tenantId }),
        resourceTypes: NotificationsV2Route.resourceTypes,
        hideIfHasResourceTypes: NotificationsV2Route.hideIfHasResourceTypes
      },
      {
        name: T.admin.globalnotifications.title,
        icon: <Icons.Notification />,
        path: (tenantId: string) =>
          generatePath(GlobalNotificationsRoute.path, { tenantId }),
        resourceTypes: GlobalNotificationsRoute.resourceTypes,
        hideIfHasResourceTypes: GlobalNotificationsRoute.hideIfHasResourceTypes
      },
      {
        name: T.admin.tabs.alarmrules,
        icon: <Icons.Alarm />,
        path: (tenantId: string) =>
          generatePath(AlarmRulesRoute.path, { tenantId }),
        resourceTypes: AlarmRulesRoute.resourceTypes,
        hideIfHasResourceTypes: LocationRoute.hideIfHasResourceTypes
      },
      {
        name: T.admin.tabs.ectotable,
        icon: <Icons.Battery />,
        path: (tenantId: string) => `/${tenantId}/ectotable`,
        resourceTypes: EctotableRoute.resourceTypes
      },

      {
        name: T.admin.tabs.users,
        icon: <Icons.User />,
        path: (tenantId: string) => `/${tenantId}/tenantUsers`,
        resourceTypes: TenantUsersAdminRoute.resourceTypes,
        hideIfHasResourceTypes: LocationRoute.hideIfHasResourceTypes
      },
      {
        name: T.admin.tabs.integrations,
        icon: <Icons.Data />,
        path: (tenantId: string) => `/${tenantId}/integrations`,
        resourceTypes: IntegrationsRoute.resourceTypes,
        hideIfHasResourceTypes: LocationRoute.hideIfHasResourceTypes,
        featureFlag: 'integrations'
      },
      {
        name: T.admin.dashboards.title,
        icon: <Icons.Dashboard />,
        path: (tenantId: string) =>
          generatePath(DashboardsManagementRoute.path, { tenantId }),
        resourceTypes: DashboardsManagementRoute.resourceTypes,
        hideIfHasResourceTypes: DashboardsManagementRoute.hideIfHasResourceTypes
      },

      {
        name: T.admin.tabs.processmaps,
        icon: <Icons.Settings />,
        path: (tenantId: string) =>
          generatePath(ProcessMapsManagementRoute.path, { tenantId }),
        resourceTypes: ProcessMapsManagementRoute.resourceTypes,
        hideIfHasResourceTypes:
          ProcessMapsManagementRoute.hideIfHasResourceTypes
      },
      {
        name: T.admin.tabs.integrationaccounts,
        icon: <Icons.Magic />,
        path: (tenantId: string) => `/${tenantId}/integration-accounts`,
        resourceTypes: IntegrationAccountsRoute.resourceTypes,
        hideIfHasResourceTypes: LocationRoute.hideIfHasResourceTypes
      },
      {
        name: T.admin.tabs.managetenants,
        icon: <Icons.User />,
        path: (tenantId: string) => `/${tenantId}/tenants`,
        resourceTypes: GlobalTenantAdminRoute.resourceTypes,
        hideIfHasResourceTypes: GlobalTenantAdminRoute.hideIfHasResourceTypes
      }
    ]
  },
  {
    title: T.admin.tabs.installation,
    links: [
      {
        path: (tenantId: string) => `/${tenantId}/buildings`,
        name: T.location.page.buildings,
        icon: <Icons.Building />,
        resourceTypes: BuildingsRoute.resourceTypes,
        hideIfHasResourceTypes: BuildingsRoute.hideIfHasResourceTypes
      },
      {
        path: (tenantId: string) => `/${tenantId}/provisioning`,
        name: T.location.page.provisioning,
        icon: <Icons.EnergyManager />,
        resourceTypes: ProvisioningRoute.resourceTypes,
        hideIfHasResourceTypes: ProvisioningRoute.hideIfHasResourceTypes
      }
    ]
  },
  {
    title: T.admin.tabs.templates,
    links: [
      {
        path: (tenantId: string) =>
          `/${tenantId}/templateManagement/equipments`,
        name: T.admin.equipmenttypes.title,
        icon: <Icons.Equipment />,
        resourceTypes: TemplateManagementRoute.resourceTypes,
        hideIfHasResourceTypes: TemplateManagementRoute.hideIfHasResourceTypes
      },
      {
        path: (tenantId: string) => `/${tenantId}/templateManagement/alarms`,
        name: T.admin.tabs.alarmtemplates,
        icon: <Icons.Alarm />,
        resourceTypes: TemplateManagementRoute.resourceTypes,
        hideIfHasResourceTypes: TemplateManagementRoute.hideIfHasResourceTypes
      },
      {
        path: (tenantId: string) => `/${tenantId}/templateManagement/buildings`,
        name: T.admin.tabs.equipmentgrouptemplates,
        icon: <Icons.Building />,
        resourceTypes: TemplateManagementRoute.resourceTypes,
        hideIfHasResourceTypes: TemplateManagementRoute.hideIfHasResourceTypes
      },
      {
        path: (tenantId: string) =>
          `/${tenantId}/templateManagement/signaltypes`,
        name: T.admin.tabs.signaltypes,
        icon: <Icons.Signal />,
        resourceTypes: TemplateManagementRoute.resourceTypes,
        hideIfHasResourceTypes: TemplateManagementRoute.hideIfHasResourceTypes
      },
      {
        path: (tenantId: string) => `/${tenantId}/templateManagement/deploy`,
        name: T.admin.tabs.deploytemplates,
        icon: <Icons.Download />,
        resourceTypes: TemplateManagementRoute.resourceTypes,
        hideIfHasResourceTypes: TemplateManagementRoute.hideIfHasResourceTypes
      }
    ]
  }
];

interface AdminPageProps {
  allowNonAdminRole?: boolean;
  content: React.ReactNode;
}

const AdminPage = ({ content, ...props }: AdminPageProps) => {
  const dispatch = useAdminDispatch();
  const { tenantResources, tenantUserRoles } = useContext(TenantContext);
  let _content = content;

  if (!hasAdminRole(tenantUserRoles)) {
    _content = <NoAdminAccessInfo />;
  }

  useEffect(() => {
    return () => {
      _.defer(() => {
        dispatch(resetRequests());
      });
    };
  }, [dispatch]);

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const filteredNavLinks = useMemo(() => {
    return navLinksFilteredByTenantResources(
      adminNavLinks,
      tenantResources,
      featureFlagState
    );
  }, [tenantResources, featureFlagState]);

  return (
    <>
      <Page navLinks={filteredNavLinks} content={_content} {...props} />
    </>
  );
};

export default AdminPage;
