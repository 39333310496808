import React, { PureComponent } from 'react';
import styles from './Icons.module.css';
import classNames from 'classnames';
import SvgClusterSolid from 'ecto-common/lib/Icons/Custom/SvgClusterSolid';
import ActiveBalancingUnit from 'ecto-common/lib/Icons/Custom/ActiveBalancingUnit';
import PassiveBalancingUnit from 'ecto-common/lib/Icons/Custom/PassiveBalancingUnit';
import HouseUnit from 'ecto-common/lib/Icons/Custom/HouseUnit';
import EctoGridLogo from 'ecto-common/lib/Icons/Custom/EctoGridLogo';
import HeatingLogo from 'ecto-common/lib/Icons/Custom/HeatingIcon';
import CoolingLogo from 'ecto-common/lib/Icons/Custom/CoolingIcon';

import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faArrowCircleRight,
  faArrowRight,
  faBell,
  faBezierCurve,
  faBroadcastTower,
  faBolt,
  faCalculator,
  faCaretRight,
  faCaretLeft,
  faCaretDown,
  faCaretUp,
  faCarBattery,
  faChartBar,
  faArrowsAltV,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCodeBranch,
  faCogs,
  faCompressArrowsAlt,
  faDatabase,
  faDownload,
  faEllipsisV,
  faEnvelope,
  faLeftRight,
  faEraser,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faExpandArrowsAlt,
  faFilter,
  faFolder,
  faFileAlt,
  faFileExport,
  faFilePdf,
  faHdd,
  faHeart,
  faHistory,
  faHome,
  faIndustry,
  faList,
  faLock,
  faInfoCircle,
  faMapMarker,
  faMicrochip,
  faMinus,
  faNetworkWired,
  faPalette,
  faPlug,
  faPlus,
  faPowerOff,
  faPrint,
  faQuestionCircle,
  faQuestion,
  faSave,
  faSearch,
  faSearchPlus,
  faSearchMinus,
  faServer,
  faTable,
  faSignInAlt,
  faSignOutAlt,
  faSolarPanel,
  faSun,
  faStar,
  faSyncAlt,
  faThermometerEmpty,
  faTimes,
  faTools,
  faUndo,
  faUser,
  faUserFriends,
  faUserPlus,
  faWindowMaximize,
  faWrench,
  faMagic,
  faThLarge,
  faBars,
  faBullseye,
  faRedo,
  faRepeat
} from '@fortawesome/free-solid-svg-icons';

import {
  faFolder as faFolderRegular,
  faFolderOpen,
  faFile,
  faCalendarAlt,
  faPlusSquare,
  faUser as faUserRegular,
  faEdit,
  faMessage,
  faStar as faStarOutline,
  faTrashAlt as faTrashAltOutline,
  faCopy,
  faPaste
} from '@fortawesome/free-regular-svg-icons';

import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import EctoplannerIcon from 'ecto-common/lib/Icons/Custom/EctoplannerIcon';

type InternalIconProps = Omit<FontAwesomeIconProps, 'icon'> & {
  icon?: IconDefinition;
  light?: boolean;
  large?: boolean;
  className?: string;
  withRightMargin?: boolean;
  withLeftMargin?: boolean;
};

const InternalIcon = ({
  icon,
  light,
  large,
  className,
  withRightMargin,
  withLeftMargin,
  ...props
}: InternalIconProps) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      size={large ? '2x' : '1x'}
      className={classNames(
        light && styles.lighter,
        withRightMargin && styles.rightMargin,
        withLeftMargin && styles.leftMargin,
        className
      )}
      {...props}
    />
  );
};

type InternalCustomIconProps = Omit<InternalIconProps, 'icon'> & {
  component: React.FC;
};

const InternalCustomIcon = (props: InternalCustomIconProps) => {
  const {
    light,
    large,
    className,
    withRightMargin,
    withLeftMargin,
    component
  } = props;
  return React.createElement(component, {
    // TODO: Investigate current color missing attribute
    // @ts-ignore
    fill: 'currentColor',
    className: classNames(
      large && styles.large,
      !large && styles.small,
      light && styles.lighter,
      withRightMargin && styles.rightMargin,
      withLeftMargin && styles.leftMargin,
      className
    )
  });
};

const icon = (iconType: IconDefinition) => {
  return (props: InternalIconProps) => {
    return <InternalIcon icon={iconType} {...props} />;
  };
};

const customIcon = (iconType: React.FC) => {
  return (props: InternalIconProps) => {
    return <InternalCustomIcon component={iconType} {...props} />;
  };
};

// This is a React component in order to help the poor completion engine of WebStorm
class Icons extends PureComponent {
  static Actions = icon(faTools);
  static Add = icon(faPlus);
  static AddPanel = icon(faPlusSquare);
  static AddUser = icon(faUserPlus);
  static Alarm = icon(faExclamationTriangle);
  static AlarmSignalClusters = customIcon(SvgClusterSolid);
  static AlarmEventList = icon(faList);
  static AlarmBell = icon(faBell);
  static ArrowRight = icon(faArrowRight);
  static BarGraph = icon(faChartBar);
  static BarMenu = icon(faBars);
  static Battery = icon(faCarBattery);
  static Building = icon(faHome);
  static Calendar = icon(faCalendarAlt);
  static Calculator = icon(faCalculator);
  static CircleMenu = icon(faEllipsisV);
  static Checkmark = icon(faCheck);
  static CheckmarkCircle = icon(faCheckCircle);
  static Close = icon(faTimes);
  static Connect = icon(faCodeBranch);
  static Connection = icon(faPlug);
  static Contract = icon(faCompressArrowsAlt);
  static Color = icon(faPalette);
  static Copy = icon(faCopy);
  static Data = icon(faDatabase);
  static Dashboard = icon(faThLarge);
  static Delete = icon(faTrashAltOutline);
  static Deploy = icon(faArrowCircleRight);
  static DoubleRight = icon(faAngleDoubleRight);
  static DoubleLeft = icon(faAngleDoubleLeft);
  static Download = icon(faDownload);
  static Duplicate = icon(faPaste);
  static Edit = icon(faEdit);
  static Email = icon(faEnvelope);
  static Eraser = icon(faEraser);
  static External = icon(faExternalLinkAlt);
  static EnergyManager = icon(faServer);
  static Equipment = icon(faHdd);
  static Error = icon(faExclamationCircle);
  static Expand = icon(faExpandArrowsAlt);
  static AnalyzeAndExport = icon(faFileExport);
  static Favorite = icon(faStar);
  static FavoriteOutline = icon(faStarOutline);
  static File = icon(faFile);
  static Filter = icon(faFilter);
  static Folder = icon(faFolder);
  static FolderOpen = icon(faFolderOpen);
  static FolderClosed = icon(faFolderRegular);
  static Graph = icon(faChartLine);
  static Hardware = icon(faMicrochip);
  static Heartbeat = icon(faHeart);
  static History = icon(faHistory);
  static Industrial = icon(faIndustry);
  static InfoCircle = icon(faInfoCircle);
  static Login = icon(faSignInAlt);
  static Logout = icon(faSignOutAlt);
  static Logs = icon(faFileAlt);
  static Lock = icon(faLock);
  static DataJobs = icon(faRepeat);
  static MapMarker = icon(faMapMarker);
  static MenuDownArrow = icon(faCaretDown);
  static MenuUpArrow = icon(faCaretUp);
  static MenuRightArrow = icon(faCaretRight);
  static MenuLeftArrow = icon(faCaretLeft);
  static Message = icon(faMessage);
  static MinMax = icon(faArrowsAltV);
  static NavigationArrowDown = icon(faChevronDown);
  static NavigationArrowUp = icon(faChevronUp);
  static NavigationArrowLeft = icon(faChevronLeft);
  static NavigationArrowRight = icon(faChevronRight);
  static Network = icon(faNetworkWired);
  static NewDocument = icon(faFile);
  static Notification = icon(faExclamationTriangle);
  static Panel = icon(faWindowMaximize);
  static Parents = icon(faBezierCurve);
  static Print = icon(faPrint);
  static Ping = icon(faBroadcastTower);
  static Question = icon(faQuestion);
  static QuestionCircle = icon(faQuestionCircle);
  static Reboot = icon(faPowerOff);
  static Redo = icon(faRedo);
  static Resize = icon(faLeftRight);
  static Refresh = icon(faSyncAlt);
  static RestartService = icon(faSyncAlt);
  static Save = icon(faSave);
  static Search = icon(faSearch);
  static Settings = icon(faCogs);
  static Signal = icon(faChartLine);
  static Site = icon(faMapMarker);
  static Subtract = icon(faMinus);
  static SolarPanel = icon(faSolarPanel);
  static Table = icon(faTable);
  static Temperature = icon(faThermometerEmpty);
  static TemperatureImpact = icon(faThermometerEmpty);
  static ToastAlert = icon(faExclamation);
  static Tool = icon(faWrench);
  static Share = icon(faUserFriends);
  static Undo = icon(faUndo);
  static User = icon(faUser);
  static UserRegular = icon(faUserRegular);
  static Weather = icon(faSun);
  static Zoom = icon(faSearchPlus);
  static ZoomOut = icon(faSearchMinus);
  static Magic = icon(faMagic);
  static Bullseye = icon(faBullseye);
  static ActiveBalancingUnit = customIcon(ActiveBalancingUnit);
  static PassiveBalancingUnit = customIcon(PassiveBalancingUnit);
  static HouseUnit = customIcon(HouseUnit);
  static EctoplannerLogo = customIcon(EctoplannerIcon);
  static EctoGridLogo = customIcon(EctoGridLogo);
  static HeatingGrid = customIcon(HeatingLogo);
  static CoolingGrid = customIcon(CoolingLogo);
  static ElectricityGrid = icon(faBolt);

  static FileTypes = {
    Pdf: icon(faFilePdf),
    Text: icon(faFileAlt)
  };
}

export default Icons;
