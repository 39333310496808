export const getNodePage = (tenantId: string, node: { nodeId: string }) => {
  return `/${tenantId}/home/${node.nodeId}/dashboard`;
};

export const SignalProvidersPageValue = 'signalproviders';

export const getSignalProvidersPage = (
  tenantId: string,
  nodeId: string,
  equipmentId: string,
  searchParams?: URLSearchParams
) => {
  let path = `/${tenantId}/home/${nodeId}/${SignalProvidersPageValue}`;

  if (equipmentId) {
    path = `${path}/${equipmentId}`;
  }

  if (searchParams?.size > 0) {
    return `${path}?${searchParams.toString()}`;
  }

  return path;
};

export const getAlarmsPage = (tenantId: string, nodeId: string) =>
  `/${tenantId}/home/${nodeId}/alarms/signalClusters`;
