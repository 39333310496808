import React, { useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import AdminPage from '../AdminPage';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import CRUDView from 'ecto-common/lib/CRUDView/CRUDView';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import PresentationAPIGen, {
  InformationModel
} from 'ecto-common/lib/API/PresentationAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';

const useModels = (): ModelDefinition<InformationModel>[] => {
  const { tenants } = useContext(TenantContext);

  return [
    {
      key: (input) => input.message,
      modelType: ModelType.TEXT,
      label: T.common.message,
      placeholder: T.admin.notifications.header.name,
      hasError: (input) => _.isEmpty(input)
    },
    {
      key: (input) => input.tenants,
      modelType: ModelType.OPTIONS,
      label: T.tenants.tenants,
      options: (): GenericSelectOption<string>[] => {
        return sortByLocaleCompare(
          tenants.map((tenant) => ({
            label: tenant.name,
            value: tenant.id
          })),
          'label'
        );
      },
      isMultiOption: true
    }
  ];
};
const createNewItem = (): InformationModel => ({
  message: ''
});

const useColumns = (): DataTableColumnProps<InformationModel>[] => {
  const { tenants } = useContext(TenantContext);
  const tenantMap = useMemo(() => {
    return _.keyBy(tenants, 'id');
  }, [tenants]);
  return [
    {
      dataKey: 'message',
      label: T.common.message
    },
    {
      dataKey: 'tenants',
      label: T.tenants.tenants,
      dataFormatter: (itemTenants: string[]) => {
        const sorted = sortByLocaleCompare(itemTenants, 'name');
        return sorted.map((tenantId) => tenantMap[tenantId]?.name).join(', ');
      }
    }
  ];
};
const GlobalNotifications = () => {
  useEffect(() => {
    document.title = T.admin.globalnotifications.title;
  }, []);

  const { contextSettings } = useContext(TenantContext);

  const useListQueryHook = () =>
    useInfiniteQuery({
      queryKey: [
        contextSettings.tenantId,
        PresentationAPIGen.Information.getAllInformation.path(contextSettings)
      ],
      queryFn: ({ signal }) => {
        return PresentationAPIGen.Information.getAllInformation.promise(
          contextSettings,
          signal
        );
      },
      initialPageParam: 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getNextPageParam: (values: any) => values[0]
    });

  const queryClient = useQueryClient();

  const updateItemMutation = useMutation({
    mutationFn: (item: InformationModel) =>
      PresentationAPIGen.Information.updateInformation.promise(
        contextSettings,
        { id: item.id },
        item,
        null
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey:
          PresentationAPIGen.Information.getAllInformation.path(contextSettings)
      });
    }
  });

  const createItemMutation =
    PresentationAPIGen.Information.createInformation.useMutation({});

  const deleteItemMutation = useMutation({
    mutationFn: (item: InformationModel) => {
      return PresentationAPIGen.Information.deleteInformation.promise(
        contextSettings,
        { id: item.id },
        null
      );
    }
  });

  const models = useModels();
  const columns = useColumns();
  return (
    <CRUDView
      models={models}
      columns={columns}
      createNewItem={createNewItem}
      itemName={'message'}
      title={T.admin.globalnotifications.title}
      editTitle={T.admin.notifications.editnotification}
      addTitle={T.admin.notifications.addnotification}
      listQueryHook={useListQueryHook}
      deleteItemMutation={deleteItemMutation}
      updateItemMutation={updateItemMutation}
      createItemMutation={createItemMutation}
    />
  );
};

const GlobalNotificationsPage = () => {
  return <AdminPage content={<GlobalNotifications />} />;
};

export default React.memo(GlobalNotificationsPage);
