import React from 'react';

import AddButton from 'ecto-common/lib/Button/AddButton';
import EditButton from 'ecto-common/lib/Button/EditButton';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import {
  ProcessMapRule,
  ProcessMapRuleType,
  ProcessMapRuleTypeOptions,
  ProcessMapRuleTypes,
  ProcessMapSymbolRule
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import SignalTypePicker from 'ecto-common/lib/SignalTypePicker/SignalTypePicker';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import T from 'ecto-common/lib/lang/Language';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import _ from 'lodash';
import { ProcessMapSymbolRuleEditorProps } from './ProcessMapObjectEditor';
import ModelEditorSignal, {
  SignalModelDefinition
} from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSignal';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import styles from './ProcessMapSymbolRuleEditor.module.css';

export const ProcessMapSymbolRuleEditor = ({
  rawValue,
  updateItem,
  customNodeTreeSet,
  nodeId,
  states
}: ProcessMapSymbolRuleEditorProps) => {
  const [isShowingDialog, showDialog, hideDialog] = useSimpleDialogState();

  const selectOptions = _.map(states, (state) => ({
    label: state,
    value: state
  }));

  const symbolRuleColumns: DataTableColumnProps<ProcessMapSymbolRule>[] = [
    {
      dataKey: 'state',
      label: T.admin.processmaps.objecteditor.state,
      flexGrow: 0,
      minWidth: 200,
      dataFormatter: (value: string, _object: unknown, rowIndex: number) => {
        return (
          <Select<GenericSelectOption<string>>
            options={selectOptions}
            value={selectOptions.find((option) => option.value === value)}
            onChange={(newVal) => {
              const newRules = _.cloneDeep(rawValue);
              newRules[rowIndex].state = newVal.value;
              updateItem(newRules);
            }}
          />
        );
      }
    },
    {
      dataKey: 'condition.signalTypeId',
      label: T.signals.signaltype,
      dataFormatter: (value: string, _object, rowIndex) => {
        return (
          <SignalTypePicker
            hasError={value == null && _object.condition.signalId == null}
            value={value}
            onChange={(newVal) => {
              const newRules = _.cloneDeep(rawValue);
              newRules[rowIndex].condition.signalTypeId = newVal;
              updateItem(newRules);
            }}
            customNodeTreeSet={customNodeTreeSet}
            nodeId={nodeId}
          />
        );
      }
    },
    {
      dataKey: 'condition.signalId',
      label: T.signals.signalid.label,
      dataFormatter: (value: string, _object, rowIndex) => {
        const model: SignalModelDefinition<ProcessMapRule> = {
          label: null,
          key: (input) => input.signalId,
          modelType: ModelType.SIGNAL,
          customNodeTreeSet,
          nodeId,
          isClearable: true
        };

        return (
          <ModelEditorSignal
            isLoading={false}
            helpText={null}
            useTooltipHelpTexts={false}
            isHorizontal={false}
            modelIsLoading={false}
            environment={null}
            model={model}
            disabled={false}
            rawValue={value}
            hasError={false}
            updateItem={(newVal: string) => {
              const newRules = _.cloneDeep(rawValue);
              newRules[rowIndex].condition.signalId = newVal;
              updateItem(newRules);
            }}
          />
        );
      }
    },
    {
      dataKey: 'condition.type',
      flexGrow: 0,
      minWidth: 100,
      dataFormatter: (value: string, _object, rowIndex) => {
        return (
          <Select<GenericSelectOption<string>, false>
            options={ProcessMapRuleTypeOptions}
            value={ProcessMapRuleTypeOptions.find(
              (option) => option.value === value
            )}
            onChange={(newVal) => {
              const newRules = _.cloneDeep(rawValue);
              newRules[rowIndex].condition.type =
                newVal.value as ProcessMapRuleType;
              updateItem(newRules);
            }}
          />
        );
      }
    },
    {
      dataKey: 'condition.value',
      flexGrow: 0,
      minWidth: 70,
      dataFormatter: (value: number, _object, rowIndex) => {
        return (
          <TextInput
            type="number"
            value={value}
            onChange={(event) => {
              const newNum = _.parseInt(event.target.value, 10);
              if (!isNaN(newNum)) {
                const newRules = _.cloneDeep(rawValue);
                newRules[rowIndex].condition.value = newNum;
                updateItem(newRules);
              }
            }}
          />
        );
      }
    },
    ...standardColumns({
      onDelete: (_item, index) => {
        updateItem(_.filter(rawValue, (_rule, i) => i !== index));
      }
    })
  ];

  return (
    <>
      <EditButton onClick={showDialog}>
        {T.admin.processmaps.objecteditor.changestatetitle}...
      </EditButton>
      <ActionModal
        isOpen={isShowingDialog}
        onModalClose={hideDialog}
        onConfirmClick={hideDialog}
        title={T.admin.processmaps.objecteditor.changestatetitle}
        headerIcon={Icons.Edit}
        actionText={T.common.done}
        disableCancel
        large
        className={styles.modal}
      >
        <DataTable<ProcessMapSymbolRule>
          data={rawValue}
          columns={symbolRuleColumns}
          noDataText={T.admin.processmaps.objecteditor.nosymbolrules}
        />
        <DataTableFooter>
          <AddButton
            onClick={() => {
              updateItem(
                _.concat(rawValue, {
                  state: null,
                  condition: {
                    type: ProcessMapRuleTypes.Equal,
                    signalTypeId: null,
                    signalId: null,
                    value: 1
                  }
                })
              );
            }}
          >
            {T.admin.processmaps.objecteditor.addsymbolrule}
          </AddButton>
        </DataTableFooter>
      </ActionModal>
    </>
  );
};
