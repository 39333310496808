import React, {
  useCallback,
  useContext,
  useMemo,
  useSyncExternalStore
} from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { useAlarmCountEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import Icons from 'ecto-common/lib/Icons/Icons';
import { AlarmSeverityText, ROOT_NODE_ID } from 'ecto-common/lib/constants';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import HelpPaths from 'ecto-common/help/tocKeys';

import { buildingStatusSection } from './panelUtil';
import styles from './AlarmCountPanel.module.css';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { formatNumber } from 'ecto-common/lib/utils/stringUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UrlContext from 'ecto-common/lib/hooks/UrlContext';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';

const MIN_BOTTOM_AREA_HEIGHT = 128;

type AlarmCountPanelProps = CustomPanelProps & {
  data: {
    node?: SingleGridNode;
    buildingStatus: BuildingStatus[];
  };
};

const AlarmCountPanel = ({ data, panelApi }: AlarmCountPanelProps) => {
  const { size } = panelApi;
  const parentNode = data.node;
  const { tenantId } = useContext(TenantContext);

  const nodeIds = useMemo(() => {
    if (parentNode?.nodeId.startsWith(ROOT_NODE_ID)) {
      return parentNode.children.map((x) => x.nodeId);
    }

    return _.compact([parentNode?.nodeId]);
  }, [parentNode]);

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const alarmCounts = useAlarmCountEventHubSubscription(
    parentNode?.grid,
    nodeIds,
    // Previous alarms version 1 was too slow on recursive calls, hence the default false.
    // Use recursive in new alarms api, so we get correct number of alarms for parent node
    featureFlagState.oldalarms ? false : true,
    data?.buildingStatus
  );

  const navigate = useNavigate();
  const { isAdmin } = useContext(DashboardDataContext);

  const { width, height } = size;

  const circleSize = Math.max(
    100,
    Math.min(height - MIN_BOTTOM_AREA_HEIGHT, width / 2.5)
  );
  const totalCircleSize = Math.max(31, circleSize / 4.25);
  const fontSize = circleSize / 4;
  const totalFontSize = Math.max(15.0, circleSize / 10.0);
  const padding = circleSize / 25.0;
  const totalCount = _.sum(_.map(alarmCounts.totalCount));

  const bySeverity: Record<string, number> = _.reduce(
    alarmCounts.bySeverity,
    (result, severityDict) => {
      _.forEach(severityDict, (count: number, severity) => {
        result[severity] = _.add(result[severity], count);
      });
      return result;
    },
    {} as Record<number, number>
  );

  const { getAlarmUrl, getAlarmUrlV2 } = useContext(UrlContext);

  const showAlarms = useCallback(() => {
    if (!isAdmin && parentNode) {
      if (!featureFlagState.oldalarms) {
        navigate(getAlarmUrlV2(tenantId, parentNode.nodeId, null));
      } else {
        navigate(getAlarmUrl(tenantId, parentNode.nodeId, null));
      }
    }
  }, [
    isAdmin,
    parentNode,
    featureFlagState.oldalarms,
    navigate,
    getAlarmUrlV2,
    tenantId,
    getAlarmUrl
  ]);

  const totalCountStr = formatNumber(totalCount, 0);
  const numDigits = totalCountStr.length;
  // Slight horizontal adjustment based on number of digits in total count text
  // Move text more to the right the larger the string is
  const offsetRight = -(numDigits - 1) * 3;

  return (
    <div className={styles.container}>
      <div
        className={styles.circle}
        style={{
          flexShrink: 0,
          width: circleSize,
          height: circleSize,
          fontSize
        }}
      >
        <Icons.AlarmBell size="2x" />
        {!alarmCounts.isLoading && (
          <div
            className={styles.totalCountCircle}
            style={{
              minWidth: totalCircleSize,
              minHeight: totalCircleSize,
              fontSize: totalFontSize,
              padding,
              right: offsetRight
            }}
          >
            {totalCountStr}
          </div>
        )}
      </div>
      <div className={styles.severities}>
        {alarmCounts.isLoading && (
          <Spinner size={SpinnerSize.SMALL} className={styles.spinner} />
        )}
        {_.map(bySeverity, (count, severity) => (
          <div key={severity} className={styles.severityContainer}>
            <div
              key={severity}
              className={classNames(
                styles.severityBox,
                styles['severity-' + severity]
              )}
            >
              {AlarmSeverityText[severity]} - {formatNumber(count, 0)}
            </div>
          </div>
        ))}
      </div>
      {alarmCounts.hasError && (
        <ErrorNotice> {T.common.unknownerror}</ErrorNotice>
      )}
      <Button onClick={showAlarms}>
        {T.admin.dashboards.panels.types.alarmcount.seealarms}
      </Button>
    </div>
  );
};

export const AlarmCountPanelData = {
  helpPath: HelpPaths.docs.dashboard.dashboards.alarm_counter,
  sections: [buildingStatusSection],
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  }
};

export default React.memo(AlarmCountPanel);
